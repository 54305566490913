:root {
  --bg: #020417;
  --basis: 3rem;
  --color-accent: #FF3573;
  --color-text-rgb: 255, 255, 255;
  --color-text-on-contrast-rgb: 0, 0, 0;
  --color-text: rgb(var(--color-text-rgb));
  --color-border: rgba(255, 255, 255, 0.6);
  --color-contrast: white;
  --color-text-on-contrast: rgb(var(--color-text-on-contrast-rgb));

  background-color: var(--bg);
  color: var(--color-text);
}

* {
  font-size: 1em;
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 300;
  color: inherit;
  text-decoration: none;
  backface-visibility: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  overflow-x: hidden;
}

x-fg {
  z-index: 20;
  position: relative;
  contain: content;
  overflow: hidden;
  mix-blend-mode: color-dodge;
}

.wrapper {
  margin: auto;
  will-change: transform;
  z-index: 20;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    height: 100vh;
  }
}

.wrapper>.links {
  @media (orientation: landscape) {
    flex: 1 1 0;
  }

  color: var(--color-text);
}

.intro {
  font-size: 1.6rem;
  margin: calc(var(--basis) * 1.5) var(--basis);

  img {
    height: .8em;
    margin-right: .1em;
  }

  & .intro-para {
    margin-bottom: calc(var(--basis) * 0.5);

    +.intro-para {
      margin-bottom: 0;
    }
  }

  h1 {
    display: inline;

    strong {
      color: var(--color-accent);
    }
  }

  strong,
  s,
  em {
    cursor: help;
    font-style: normal;
    font-weight: 600;
  }

  s {
    opacity: 0.5;
    text-decoration: line-through;
    cursor: help;
  }
}

.links {
  overflow: scroll;
  border: 1px solid var(--color-border);
  border-left: 0;
  border-right: 0;

  ul {
    display: flex;
    flex-direction: column;

    @media (orientation: landscape) {
      flex-direction: row;
      height: 100%;
    }

    li {
      display: contents;
    }

    & li+li a {
      @media (orientation: portrait) {
        border-top: 1px solid var(--color-border);
      }

      @media (orientation: landscape) {
        border-left: 1px solid var(--color-border);
      }
    }
  }

  a {
    padding: var(--basis);
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0 0 0 0 transparent;
    flex-direction: column;
    contain: content;
    flex: 0 0 auto;

    @media (orientation: landscape) {
      width: 11rem;
    }

    &:hover,
    &:focus {
      transition: 0.5s;

      @media (orientation: landscape) {
        box-shadow: inset 0 100vh 0 0 var(--color-accent);
      }

      @media (orientation: portrait) {
        box-shadow: inset 100vw 0 0 0 var(--color-accent);
      }

      color: var(--color-text-on-contrast);
      filter: blur(0.5px);
    }
  }

  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.2em;
    font-weight: 200;
    opacity: 0.5;
  }

  strong {
    font-weight: 800;
    transform-origin: 0 0;
    font-size: 8vw;

    @media (orientation: landscape) {
      line-height: 1cap;
      transform: translateY(100%) rotate(-90deg);
      font-size: 8vh;
      height: 10em;
    }
  }
}


footer {
  color: var(--color-text-on-contrast);
  font-size: 0.8rem;
  font-weight: 400;
  padding: calc(var(--basis) / 3) var(--basis);
  background-color: var(--color-contrast);
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (orientation: portrait) {
    flex-direction: column;
    gap: calc(var(--basis) / 3);
  }

  strong {
    font-weight: 700;
  }

  li {
    display: inline-block;

    &:not(:last-child):after {
      content: "·";
      padding: 0 0.25em;
    }
  }

  a {
    text-decoration: underline;
    font-weight: 800;
    text-decoration-color: rgb(var(--color-text-on-contrast-rgb), 0.5);

    &:hover,
    &:focus {
      color: var(--color-accent);
      text-decoration-color: inherit;
    }
  }
}

/*
 * Backgrounds
 */

x-bg {
  z-index: 8;
  background: url(gli.jpg);
  background-size: cover;
}

x-glitch {
  mix-blend-mode: lighten;
  background: url(gli.gif);
  background-size: cover;
  z-index: 9;
  opacity: .7;
}

x-tomfoolery,
x-tomfoolery>* {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  will-change: transform;
  contain: strict;
  pointer-events: none;
}