:root {
  --bg: #020417;
  --basis: 3rem;
  --color-accent: #ff3573;
  --color-text-rgb: 255, 255, 255;
  --color-text-on-contrast-rgb: 0, 0, 0;
  --color-text: rgb(var(--color-text-rgb));
  --color-border: #fff9;
  --color-contrast: white;
  --color-text-on-contrast: rgb(var(--color-text-on-contrast-rgb));
  background-color: var(--bg);
  color: var(--color-text);
}

* {
  color: inherit;
  backface-visibility: hidden;
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 300;
  text-decoration: none;
  list-style: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  overflow-x: hidden;
}

x-fg {
  z-index: 20;
  contain: content;
  mix-blend-mode: color-dodge;
  position: relative;
  overflow: hidden;
}

.wrapper {
  will-change: transform;
  z-index: 20;
  flex-direction: column;
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;

  @media (orientation: landscape) {
    & {
      height: 100vh;
    }
  }
}

.wrapper > .links {
  color: var(--color-text);

  @media (orientation: landscape) {
    & {
      flex: 1 1 0;
    }
  }
}

.intro {
  margin: calc(var(--basis) * 1.5) var(--basis);
  font-size: 1.6rem;

  & img {
    height: .8em;
    margin-right: .1em;
  }

  & .intro-para {
    margin-bottom: calc(var(--basis) * .5);

    & + .intro-para {
      margin-bottom: 0;
    }
  }

  & h1 {
    display: inline;

    & strong {
      color: var(--color-accent);
    }
  }

  & strong, & s, & em {
    cursor: help;
    font-style: normal;
    font-weight: 600;
  }

  & s {
    opacity: .5;
    cursor: help;
    text-decoration: line-through;
  }
}

.links {
  border: 1px solid var(--color-border);
  border-left: 0;
  border-right: 0;
  overflow: scroll;

  & ul {
    flex-direction: column;
    display: flex;

    @media (orientation: landscape) {
      & {
        flex-direction: row;
        height: 100%;
      }
    }

    & li {
      display: contents;
    }

    & li + li a {
      @media (orientation: portrait) {
        & {
          border-top: 1px solid var(--color-border);
        }
      }

      @media (orientation: landscape) {
        & {
          border-left: 1px solid var(--color-border);
        }
      }
    }
  }

  & a {
    padding: var(--basis);
    contain: content;
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    display: flex;
    box-shadow: inset 0 0 #0000;

    @media (orientation: landscape) {
      & {
        width: 11rem;
      }
    }

    &:hover, &:focus {
      color: var(--color-text-on-contrast);
      filter: blur(.5px);
      transition: all .5s;

      @media (orientation: landscape) {
        & {
          box-shadow: inset 0 100vh 0 0 var(--color-accent);
        }
      }

      @media (orientation: portrait) {
        & {
          box-shadow: inset 100vw 0 0 0 var(--color-accent);
        }
      }
    }
  }

  & span {
    text-transform: uppercase;
    letter-spacing: .2em;
    opacity: .5;
    font-size: .8rem;
    font-weight: 200;
  }

  & strong {
    transform-origin: 0 0;
    font-size: 8vw;
    font-weight: 800;

    @media (orientation: landscape) {
      & {
        height: 10em;
        font-size: 8vh;
        line-height: 1cap;
        transform: translateY(100%)rotate(-90deg);
      }
    }
  }
}

footer {
  color: var(--color-text-on-contrast);
  padding: calc(var(--basis) / 3) var(--basis);
  background-color: var(--color-contrast);
  flex-direction: row;
  justify-content: space-between;
  font-size: .8rem;
  font-weight: 400;
  display: flex;

  @media (orientation: portrait) {
    & {
      gap: calc(var(--basis) / 3);
      flex-direction: column;
    }
  }

  & strong {
    font-weight: 700;
  }

  & li {
    display: inline-block;

    &:not(:last-child):after {
      content: "·";
      padding: 0 .25em;
    }
  }

  & a {
    text-decoration: underline;
    text-decoration-color: rgb(var(--color-text-on-contrast-rgb), .5);
    font-weight: 800;

    &:hover, &:focus {
      color: var(--color-accent);
      text-decoration-color: inherit;
    }
  }
}

x-bg {
  z-index: 8;
  background: url("gli.93ca0817.jpg") 0 0 / cover;
}

x-glitch {
  mix-blend-mode: lighten;
  z-index: 9;
  opacity: .7;
  background: url("gli.93892c0a.gif") 0 0 / cover;
}

x-tomfoolery, x-tomfoolery > * {
  will-change: transform;
  contain: strict;
  pointer-events: none;
  position: absolute;
  inset: 0;
}
/*# sourceMappingURL=index.95c64bb5.css.map */
